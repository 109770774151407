<template>
  <div class="color__block"
       v-bind:class="{border_color: borderColor }"
       v-bind:style="{ background: '#' + codesColor.hex,
                       border: codesColor.hex === 'F2F5F5'? '1px solid #B5C7CB':'',
                      }">
    <div class="color__info" :style="{color: `#${colorText}`}">
      <div class="color__title">
        {{ title }}
      </div>
      <div class="color__code">
        <span :style="codesColor[activeFormat.toLowerCase()].trim() ? {opacity: 0.6} : null">{{ activeFormat }}:</span>
        <p v-if="codesColor[activeFormat.toLowerCase()].trim()" v-html="codesColor[activeFormat.toLowerCase()]"></p>

        <p v-else :style="{opacity: 0.6}">N/A</p>
      </div>
    </div>
    <div class="color__copy-block">
      <button v-bind:class="{'not-bor':text !== 'Скопировать'}"
              @click.stop.prevent="copyCode(codesColor[activeFormat.toLowerCase()])">
        {{ text }}
      </button>
    </div>
    <input type="hidden"
           v-bind:id="idColor"
           class="text-color "
           :value="colorCode">
  </div>
</template>
<script>
export default {
  props: [
    'title',
    'id',
    'activeFormat',
    'codesColor',
    'headText',
    'colorText',
    'borderColor'
  ],
  data() {
    return {
      colorCode: this.codesColor[this.activeFormat.toLowerCase()],
      idColor: `color-block-${this.id}`,
      text: 'Скопировать'
    }
  },
  methods: {
    copyCode(activeFormat) {
      const inp = document.createElement('input');
      document.body.appendChild(inp)
      inp.value = activeFormat
      inp.select();
      document.execCommand('copy', false);
      inp.remove();
      this.text = 'Успешно скопировано'
      setTimeout(() => {
        this.text = 'Скопировать'
      }, 2000)
    },
  }
}
</script>
<style lang="scss" scoped>
input.text-color {
  display: none;
}

.color {
  &__block {
    width: 100%;
    height: 256px;
    position: relative;
    overflow: hidden;
    padding: 32px 24px;
    margin-bottom: 32px;
    font-weight: 300;
    font-family: 'suisse_intl', sans-serif;

    &:hover {
      .color__copy-block {
        z-index: 1;
        opacity: 1;
      }
    }
  }

  &__head {
    color: #FFFFFF;
    opacity: 0.6;
    position: absolute;
    top: 24px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.02em;
    max-width: 2px;
  }

  &__info {
    position: absolute;
    bottom: 13px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 72px;

  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
    letter-spacing: 0.15px;
    font-family: 'suisse_intl', sans-serif;
  }

  &__code {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-family: 'suisse_intl', sans-serif;
    font-weight: 200;
    display: flex;
    letter-spacing: .2px;

    span {
      text-transform: capitalize;
      font-family: 'suisse_intl', sans-serif;
      opacity: 0.6;
      font-weight: 200;
    }

    p {
      font-family: 'suisse_intl', sans-serif;
      font-weight: 200;

      padding-left: 4px;
      text-transform: capitalize;
      letter-spacing: 0.6px;
    }
  }

  &__copy-block {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, .1);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: all .2s ease-in-out;

    button {
      padding: 11px 32px;
      font-weight: 200;
      border-radius: 40px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.02em;
      border: 1px solid #fff;
      color: #fff;
      cursor: pointer;
      background: rgba(0, 0, 0, 0);
      font-family: 'suisse_intl', sans-serif;

      &.not-bor {
        border: none;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.border_color {
  border: 1px solid #606080;
}

.page__bottom-margin {
  margin-bottom: 82px;
}

.text__head {
  letter-spacing: 0.1px;
}
</style>
