<template>
  <div class="gradient-block"
       :class="gradient.class"
       :style="{background: `linear-gradient(90deg, #${gradient.first.colorCodes.hex} 0.01%,
        #${gradient.second.colorCodes.hex} 99.99%)`}">
    <div class="gradient-block__left">
      <div class="gradient-block__left-info">
        <div class="gradient-block__title">
          {{ gradient.first.title }}
        </div>
        <div class="gradient-block__code">
          <span>{{ activeFormat }}:</span>
          <p v-if="gradient.first.colorCodes[activeFormat.toLowerCase()]" v-html="gradient.first.colorCodes[activeFormat.toLowerCase()]"></p>
          <p v-else :style="{opacity: 0.6}">N/A</p>
        </div>
      </div>
    </div>
    <div class="gradient-block__right">
      <div class="gradient-block__right-info">
        <div class="gradient-block__title">
          {{ gradient.second.title }}
        </div>
        <div :class="gradient.second.class" class="gradient-block__code">
          <span>{{ activeFormat}}:</span>
          <p v-if="gradient.second.colorCodes[activeFormat.toLowerCase()]" v-html="gradient.second.colorCodes[activeFormat.toLowerCase()]"></p>
          <p v-else :style="{opacity: 0.6}">N/A</p>
        </div>
      </div>
    </div>
<!--    <div class="gradient-block__copy-block">-->
<!--      <button @click.prevent="copyCode(gradient.first.colorCodes[activeFormat.toLowerCase()],  gradient.second.colorCodes[activeFormat.toLowerCase()])">-->
<!--        Скопировать код-->
<!--      </button>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  props: ['gradient', 'activeFormat'],
  methods: {
    copyCode(activeFormat, activeFormat2) {
      var inp =document.createElement('input');
      document.body.appendChild(inp)
      inp.value =activeFormat + ' ' + activeFormat2
      inp.select();
      document.execCommand('copy',false);
      inp.remove();
      alert('Цвет скопирован')
    }
  }
}
</script>
<style lang="scss" scoped>
.gradient-block {
  width: 100%;
  overflow: hidden;
  margin-bottom: 32px;
  padding: 30px 24px;
  display: flex;
  height: 256px;
  position: relative;

  &.second-right {
    .gradient-block__right {
      .gradient-block__code {
        justify-content: flex-end;
      }
    }
  }

  &__right,
  &__left {
    width: 50%;
    position: relative;
    height: 100%;
    color: #fff;
  }

  &__right-info,
  &__left-info {
    position: absolute;
    bottom: 0;
  }

  &__right-info {
    right: 0;
    text-align: right;
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
    font-family: 'suisse_intl', sans-serif;
  }

  &__code {
    display: flex;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.06em;
    span {
      opacity: 0.6;
      font-weight: 200;
      font-family: 'suisse_intl', sans-serif;
      text-transform: capitalize;
    }
    p {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.06em;
      font-family: 'suisse_intl', sans-serif;
      font-weight: 200;
      margin-left: 4px;
    }
  }

  &__copy-block {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100%;
    left: 0;
    background: rgba(0,0,0,.1);
    transition: top .2s ease-in-out;
    button {
      padding: 11px 32px;
      border-radius: 40px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.02em;
      border: 1px solid #fff;
      color: #fff;
      cursor: pointer;
      background: rgba(0, 0, 0, 0);
      font-family: 'suisse_intl', sans-serif;
      &:focus {
        outline: none;
      }
    }
  }
  &:hover{
    .gradient-block__copy-block{
      top: 0;
    }
  }

}
</style>
