var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page__content-wrapper color-page"},[_c('div',{staticClass:"page__min-container color-page__head"},[_c('div',{staticClass:"page__label-block",class:{ 'fixed': _vm.isFixedButtons },style:({top: _vm.topButtons})},[_vm._l((_vm.buttonsLabel),function(btn,i){return _c('button',{key:i,staticClass:"btn__label",class:[btn.isActive?'active':''],on:{"click":function($event){return _vm.changeLabel(i)}}},[_vm._v(" "+_vm._s(btn.title)+" ")])}),_c('div',{staticClass:"page__label-indent"})],2),_c('div',{staticClass:"page__content-block",style:({marginTop: _vm.marginTopContent})},[_c('div',{staticClass:"page__three-block"},[_c('ColorBlock',{attrs:{"title":'Ruby Pink',"activeFormat":_vm.activeFormat,"id":9,"codes-color":{
                      hex: 'E5175C',
                      rgb: '229, 23, 92',
                      cmyk: '0, 98, 33, 2',
                      ral: '350 40 50',
                      pantone: '2040C',
                      oracal: '8300 Transparent / 041'
                    }}})],1),_c('div',{staticClass:"page__three-block"},[_c('ColorBlock',{attrs:{"title":'Sapphire Blue',"activeFormat":_vm.activeFormat,"id":10,"codes-color":{
                      hex: '002266',
                      rgb:'0, 34, 102',
                      cmyk: '100, 85, 5, 22',
                      ral: '290 20 35',
                      pantone: '280C',
                      oracal: '641 Economy / 065'
                    }}})],1),_c('div',{staticClass:"page__three-block"},[_c('ColorBlock',{attrs:{"title":'Midnight Blue',"activeFormat":_vm.activeFormat,"id":11,"codes-color":{
                      hex: '000033',
                      rgb:'0, 0, 51',
                      cmyk: '100, 100, 9, 57',
                      ral: '290 20 20',
                      pantone: '5255C',
                      oracal: '951 Premium / 518 </br> 641 Economy / 518'
                    }}})],1)]),_c('div',{staticClass:"text__head"},[_vm._v(" Палитра серых ")]),_c('div',{staticClass:"page__content-block"},[_c('div',{staticClass:"page__three-block"},[_c('ColorBlock',{attrs:{"title":'Leaden Gray',"activeFormat":_vm.activeFormat,"id":3,"headText":'Heads',"codes-color":{
                      hex: '606080',
                      rgb: '96, 96, 128',
                      cmyk: '59 52 28 13',
                      ral: '280 40 15',
                      pantone: '2362C',
                      oracal: ''
                    }}})],1),_c('div',{staticClass:"page__three-block"},[_c('ColorBlock',{attrs:{"title":'Sky Gray',"headText":'Text',"activeFormat":_vm.activeFormat,"id":4,"colorText":'606080',"codes-color":{
                      hex: 'E6EAF2',
                      rgb:'230, 234, 242',
                      cmyk: '10 2 0 0',
                      ral: '260 90 05',
                      pantone: '656C',
                      oracal: ''
                    }}})],1),_c('div',{staticClass:"page__three-block"},[_c('ColorBlock',{attrs:{"title":'Pearl White',"activeFormat":_vm.activeFormat,"id":5,"borderColor":true,"colorText":'777F8C',"codes-color":{
                      hex: 'F5F5F5',
                      rgb:'245, 245, 245',
                      cmyk: '',
                      ral: ' ',
                      pantone: ' ',
                      oracal: '951 Premium / 010'
                      }}})],1)]),_c('div',{staticClass:"text__head"},[_vm._v(" Фирменные градиенты ")]),_c('div',{staticClass:"page__content-block"},[_c('div',{staticClass:"page__one-block"},[_c('ColorGradients',{attrs:{"activeFormat":_vm.activeFormat,"id":12,"gradient":{first:{
                                title: 'Midnight Blue',
                                colorCodes: {
                                  hex: '000033',
                                  rgb:'0, 0, 51',
                                  cmyk: '100, 100, 9, 57',
                                  ral: '',
                                  pantone: '',
                                  oracal: ''
                                  }
                              },
                              second:{
                                title: 'Sapphire Blue',
                                colorCodes: {
                                  hex: '002266',
                                  rgb:'0, 34, 102',
                                  cmyk: '100, 85, 5, 22',
                                  ral: '',
                                  pantone: '',
                                  oracal: ''
                                },
                              },
                              class: 'second-right'}}})],1)])]),_c('div',{staticClass:"page-nav__tablet"},[_c('router-link',{staticClass:"next-page__link",attrs:{"to":"/identity/logo"}},[_c('img',{attrs:{"src":require("assets/img/icons/row-grey-small.svg"),"alt":"prev"}}),_vm._v(" Логотип ")]),_c('router-link',{staticClass:"next-page__link",attrs:{"to":"/identity/typography"}},[_vm._v(" Типографика "),_c('img',{attrs:{"src":require("assets/img/icons/row-grey-small.svg"),"alt":"next"}})])],1),_c('div',{staticClass:"next-page"},[_c('router-link',{staticClass:"next-page__link",attrs:{"to":"/identity/typography"}},[_vm._v(" Типографика "),_c('img',{attrs:{"src":require("assets/img/icons/row-grey-small.svg"),"alt":"next"}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }