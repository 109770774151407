<template>
  <div class="page page__content-wrapper color-page">
    <div class="page__min-container color-page__head">
      <div class="page__label-block" :class="{ 'fixed': isFixedButtons }" :style="{top: topButtons}">
        <button class="btn__label"
                v-for="(btn, i) of buttonsLabel"
                :key="i"
                v-bind:class="[btn.isActive?'active':'']"
                @click="changeLabel(i)">
          {{ btn.title }}
        </button>
        <div class="page__label-indent"></div>
      </div>
      <div class="page__content-block" :style="{marginTop: marginTopContent}">
        <div class="page__three-block">
          <ColorBlock v-bind:title="'Ruby Pink'"
                      v-bind:activeFormat="activeFormat"
                      v-bind:id="9"
                      v-bind:codes-color="{
                        hex: 'E5175C',
                        rgb: '229, 23, 92',
                        cmyk: '0, 98, 33, 2',
                        ral: '350 40 50',
                        pantone: '2040C',
                        oracal: '8300 Transparent / 041'
                      }"/>
        </div>
        <div class="page__three-block">
          <ColorBlock v-bind:title="'Sapphire Blue'"
                      v-bind:activeFormat="activeFormat"
                      v-bind:id="10"
                      v-bind:codes-color="{
                        hex: '002266',
                        rgb:'0, 34, 102',
                        cmyk: '100, 85, 5, 22',
                        ral: '290 20 35',
                        pantone: '280C',
                        oracal: '641 Economy / 065'
                      }"/>
        </div>
        <div class="page__three-block">
          <ColorBlock v-bind:title="'Midnight Blue'"
                      v-bind:activeFormat="activeFormat"
                      v-bind:id="11"
                      v-bind:codes-color="{
                        hex: '000033',
                        rgb:'0, 0, 51',
                        cmyk: '100, 100, 9, 57',
                        ral: '290 20 20',
                        pantone: '5255C',
                        oracal: '951 Premium / 518 </br> 641 Economy / 518'
                      }"/>
        </div>
      </div>
      <div class="text__head">
        Палитра серых
      </div>
      <div class="page__content-block">
        <div class="page__three-block">
          <ColorBlock v-bind:title="'Leaden Gray'"
                      v-bind:activeFormat="activeFormat"
                      v-bind:id="3"
                      v-bind:headText="'Heads'"
                      v-bind:codes-color="{
                        hex: '606080',
                        rgb: '96, 96, 128',
                        cmyk: '59 52 28 13',
                        ral: '280 40 15',
                        pantone: '2362C',
                        oracal: ''
                      }"/>
        </div>
        <div class="page__three-block">
          <ColorBlock v-bind:title="'Sky Gray'"
                      v-bind:headText="'Text'"
                      v-bind:activeFormat="activeFormat"
                      v-bind:id="4"
                      v-bind:colorText="'606080'"
                      v-bind:codes-color="{
                        hex: 'E6EAF2',
                        rgb:'230, 234, 242',
                        cmyk: '10 2 0 0',
                        ral: '260 90 05',
                        pantone: '656C',
                        oracal: ''
                      }"/>
        </div>
        <div class="page__three-block">
          <ColorBlock v-bind:title="'Pearl White'"
                      v-bind:activeFormat="activeFormat"
                      v-bind:id="5"
                      v-bind:borderColor="true"
                      v-bind:colorText="'777F8C'"
                      v-bind:codes-color="{
                        hex: 'F5F5F5',
                        rgb:'245, 245, 245',
                        cmyk: '',
                        ral: ' ',
                        pantone: ' ',
                        oracal: '951 Premium / 010'
                        }"/>
        </div>

      </div>

      <div class="text__head">
        Фирменные градиенты
      </div>
      <div class="page__content-block">
        <div class="page__one-block">
          <ColorGradients
              v-bind:activeFormat="activeFormat"
              v-bind:id="12"
              v-bind:gradient="{first:{
                                  title: 'Midnight Blue',
                                  colorCodes: {
                                    hex: '000033',
                                    rgb:'0, 0, 51',
                                    cmyk: '100, 100, 9, 57',
                                    ral: '',
                                    pantone: '',
                                    oracal: ''
                                    }
                                },
                                second:{
                                  title: 'Sapphire Blue',
                                  colorCodes: {
                                    hex: '002266',
                                    rgb:'0, 34, 102',
                                    cmyk: '100, 85, 5, 22',
                                    ral: '',
                                    pantone: '',
                                    oracal: ''
                                  },
                                },
                                class: 'second-right'}"/>
        </div>
      </div>
    </div>
    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/logo">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Логотип
      </router-link>
      <router-link class="next-page__link" to="/identity/typography">
        Типографика
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/identity/typography">
        Типографика
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
import ColorBlock from "@/components/ColorPage/ColorBlock";
import ColorGradients from "@/components/ColorPage/ColorGradients";

export default {
  components: {ColorGradients, ColorBlock},
  data() {
    return {
      buttonsLabel: [
        {title: 'Hex', isActive: true},
        {title: 'RGB', isActive: false},
        {title: 'CMYK', isActive: false},
        {title: 'RAL', isActive: false},
        {title: 'Pantone', isActive: false},
        {title: 'Oracal', isActive: false}
      ],
      activeFormat: 'Hex',
      isFixedButtons: false,
      marginTopContent: '54px',
      topButtons: '0',
      oldPageYOffset: 0,
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    changeLabel(index) {
      this.buttonsLabel.map((item, i) => {
        if (i !== index) {
          return item.isActive = false
        } else {
          this.activeFormat = item.title
          return item.isActive = true
        }
      })
    },

    handleScroll() {
      const pageYOffset = window.innerWidth > 1456
          ? 350 : window.innerWidth >= 896
              ? 440 : window.innerWidth > 420 ? 360 : 235
      this.isFixedButtons = window.pageYOffset >= pageYOffset;
      if (window.pageYOffset < pageYOffset) {
        this.marginTopContent = '54px'
      } else {
        this.marginTopContent = window.innerWidth > 896
            ? '248px' : window.innerWidth > 869
                ? '142px' : window.innerWidth > 420
                    ? '136px' : '130px'
      }

      if (window.innerWidth <= 1456) {
        if (this.oldPageYOffset > window.pageYOffset) {
          this.topButtons = '80px'
        } else {
          this.topButtons = '0'
        }
      } else {
        this.topButtons = '80px'
      }
      this.oldPageYOffset = window.pageYOffset;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/buttons";

.page {
  margin-top: 86px;
  margin-bottom: 176px;
}

.page__min-container {
  .page__content-block {
    margin-top: 72px;

    &:nth-child(2) {
      margin-bottom: 81px;
    }

    &:nth-child(4) {
      margin-bottom: 65px;
      margin-top: 31px;
    }

    &:nth-child(6) {
     margin-top: 31px;
    }
  }
}

.page__three-block {
  &:nth-child(1) {
    .color__block {

      .color__info {
        background: red;
      }
    }
  }
}

.page__label-block {
  margin-bottom: 72px;

  .page__label-indent {
    min-width: 8px;
    min-height: 8px;
    display: none;
  }
  &.fixed {
    position: fixed;
    top: 80px;
    left: 255px;
    width: 100%;
    padding-left: 128px;
    background: #F5F5F5;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    transition: top 0.2s ease-in-out;

    z-index: 2;

    .btn__label {
      margin: 24px 16px;
    }
  }
}

.btn__label {
  margin: 10px 16px 24px 16px;
  font-weight: 200;
  box-shadow: -3px -3px 4px rgba(255, 255, 255, 0.9), 3px 3px 4px rgba(228, 231, 235, 0.9);

  &:first-child {
    margin-left: 0;
  }


  &:hover {
    box-shadow: -3px -3px 8px rgba(255, 255, 255, 0.8), 3px 3px 8px rgba(228, 231, 235, 0.8), -3px -3px 16px rgba(255, 255, 255, 0.4), 3px 3px 16px rgba(228, 231, 235, 0.4);
  }

  &:hover:active {
    box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 4px #E4E7EB;
  }

  &.active {
    box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 4px #E4E7EB;
  }
}

.text__head {
  margin-bottom: 31px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 204px;

    .page__label-block {
      &.fixed {
        top: 0;
        left: 0;
        padding-left: 103.5px;
      }
    }
  }
}

@media screen and (max-width: 1216px) {
  .page {
    .page__label-block {
      &.fixed {
        padding-left: calc(((100% - 832px) / 2) - 16px);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .page {
    &.color-page {
      padding-top: 0;
    }

    .page__label-block {
      display: flex;
      overflow-y: auto;
    }
  }
}

@media screen and (max-width: 896px) {
  .page {
    &.color-page {
      margin-top: 22px;

      .page__label-block {
        margin-bottom: 0;
          .page__label-indent {
            display: block;
          }
        .btn__label {
          margin: 10px 8px;
          &:nth-child(6) {
            margin-right: 0;
          }
        }
      }

      .page__content-block {
        margin-top: 54px;
      }
    }
  }
}

</style>
